:root {
  --font-color: #3c3636;
  --hover-color:#949986;
  --bg-color:#ffffff;
  --menu-bg-color: #ffffff;
  --menu-font-color: #3c3636;
}


*{
  box-sizing: border-box;
  font-family: brother-1816, sans-serif;
  line-height: 1.4em;
  color:var(--font-color);
}

a{
  color:var(--menu-color);
  text-decoration: none;
}

a:hover{
  color:var(--hover-color);
}

h1{
  font-size: 2em;
}
h2{
  font-size: 1.3em;
}

p{
  margin-bottom: 25px;
}